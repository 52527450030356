<template>
  <div>
    <div class="overflow-x-hidden justify-between pt-16">
      <div class="flex relative z-20 items-center overflow-hidden">
        <div class="container mx-auto px-6 flex relative py-16">
          <div class="md:w-2/3 lg:w-2/5 flex flex-col relative z-20">
            <span class="w-20 h-2 bg-gray-800 mb-12"> </span>
            <h1
              class="font-bebas-neue uppercase text-6xl sm:text-8xl font-black flex flex-col leading-none text-gray-800"
            >
              The Wellness
              <span class="text-6xl sm:text-8xl"> Planner </span>
            </h1>
            <div class="md:hidden relative">
              <img
                src="@/assets/books/book_cover.png"
                class="max-w-sm md:max-w-md m-auto"
              />
            </div>
            <p class="text-sm sm:text-base text-gray-700 pt-10">
              {{ $t("pages.index.the_wellness_planner_details") }}
            </p>
          </div>
          <div class="hidden md:block md:w-1/3 lg:w-3/5 relative">
            <img
              src="@/assets/books/book_cover.png"
              class="max-w-sm md:max-w-md m-auto"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="px-4 mx-auto max-w-screen-xl mb-10">
      <div class="">
        <div class="justify-center">
          <div class="mb-6">
            <h2
              class="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none"
            >
              {{ $t("pages.books.how_to_buy") }}
            </h2>
            <p class="text-base text-gray-700">
              {{ $t("pages.books.delivery") }}
            </p>
            <p class="text-base text-gray-700 mt-2">
              {{ $t("pages.books.delivery_abroad") }}
            </p>
          </div>

          <p class="mb-4 text-sm font-bold tracking-widest uppercase">
            {{ $t("pages.index.contents") }}
          </p>
          <div class="grid space-y-3 sm:gap-2 sm:grid-cols-2 sm:space-y-0">
            <ul class="space-y-3">
              <li class="flex">
                <span class="mr-1">
                  <svg
                    class="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    stroke="currentColor"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </span>
                {{ $t("pages.index.wellness_content_1") }}
              </li>
              <li class="flex">
                <span class="mr-1">
                  <svg
                    class="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    stroke="currentColor"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </span>
                {{ $t("pages.index.wellness_content_2") }}
              </li>
              <li class="flex">
                <span class="mr-1">
                  <svg
                    class="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    stroke="currentColor"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </span>
                {{ $t("pages.index.wellness_content_3") }}
              </li>
              <li class="flex">
                <span class="mr-1">
                  <svg
                    class="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    stroke="currentColor"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </span>
                {{ $t("pages.index.wellness_content_4") }}
              </li>
            </ul>
            <ul class="space-y-3">
              <li class="flex">
                <span class="mr-1">
                  <svg
                    class="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    stroke="currentColor"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </span>
                {{ $t("pages.index.wellness_content_5") }}
              </li>
              <li class="flex">
                <span class="mr-1">
                  <svg
                    class="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    stroke="currentColor"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </span>
                {{ $t("pages.index.wellness_content_6") }}
              </li>
              <li class="flex">
                <span class="mr-1">
                  <svg
                    class="h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    stroke="currentColor"
                    fill="#10b981"
                    viewBox="0 0 1792 1792"
                  >
                    <path
                      d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                    ></path>
                  </svg>
                </span>
                {{ $t("pages.index.wellness_content_7") }}
              </li>
            </ul>
          </div>
          <section class="py-6 bg-coolGray-100 text-coolGray-900">
            <div
              class="container grid grid-cols-2 gap-4 p-4 mx-auto md:grid-cols-4"
            >
              <img
                src="@/assets/books/book_mockup_a.jpg"
                alt=""
                class="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-3 md:row-start-1 bg-coolGray-500 aspect-square"
              />
              <img
                alt=""
                class="w-full h-full rounded shadow-sm min-h-48 bg-coolGray-500 aspect-square"
                src="@/assets/books/book_mockup_b.jpg"
              />
              <img
                alt=""
                class="w-full h-full rounded shadow-sm min-h-48 bg-coolGray-500 aspect-square"
                src="@/assets/books/book_mockup_c.jpg"
              />
              <img
                alt=""
                class="w-full h-full rounded shadow-sm min-h-48 bg-coolGray-500 aspect-square"
                src="@/assets/books/book_mockup_d.jpg"
              />
              <img
                alt=""
                class="w-full h-full rounded shadow-sm min-h-48 bg-coolGray-500 aspect-square"
                src="@/assets/books/book_mockup_e.jpg"
              />
            </div>
          </section>
        </div>
      </div>
      <p class="mb-1 text-xs md:text-sm font-semibold tracking-wide md:mb-2">
        {{ $t("pages.books.return_policy") }}
      </p>
      <p class="text-xs text-gray-700 md:text-sm">
        {{ $t("pages.books.return_policy_details") }}
      </p>
    </div>

    <div class="font-bold text-4xl text-center py-16">
      {{ $t("pages.books.authors") }}
    </div>
    <div class="grid grid-cols-2">
      <div class="px-2 bg-white">
        <div class="grid grid-cols-1">
          <div
            class="h-40 w-40 mx-auto rounded-full shadow-2xl flex items-center justify-center"
          >
            <img
              class="h-40 w-40 rounded-full"
              src="@/assets/books/book_author_stavroula.jpg"
              alt="Stavroulla Argyridou profile picture"
            />
          </div>
        </div>
        <div class="mt-4 text-center pb-4">
          <h1 class="text-xl font-medium text-gray-800">
            {{ $t("pages.books.dr_stavroula") }}
          </h1>
          <p class="text-gray-700 mt-3">
            {{ $t("pages.books.registered_dietitian") }}
          </p>
          <p class="mt-3 text-gray-700">
            {{ $t("pages.books.founder") }}

            <a href="https://medindiet.com" rel="noopener noreferrer"
              >MedInDiet</a
            >
          </p>
        </div>
      </div>
      <div class="px-2 bg-white">
        <div class="grid grid-cols-1">
          <div
            class="h-40 w-40 mx-auto rounded-full shadow-2xl flex items-center justify-center"
          >
            <img
              class="h-40 w-40 rounded-full"
              src="@/assets/books/book_author_avramis.jpg"
              alt="Avraam Karagiannis profile picture"
            />
          </div>
        </div>
        <div class="mt-4 text-center pb-4">
          <h1 class="text-xl font-medium text-gray-800">
            {{ $t("pages.books.dr_avraam") }}
          </h1>
          <p class="text-gray-700 mt-3">
            {{ $t("pages.books.registered_psychotherapist") }}
          </p>
          <p class="mt-3 text-gray-700">
            {{ $t("pages.books.founder") }}
            <a href="https://www.healthiermind.com" rel="noopener noreferrer"
              >Healthier Mind</a
            >
          </p>
        </div>
      </div>
    </div>
    <!-- eBook -->
    <div class="px-5 mt-10 py-16 relative bg-gray-100">
      <div class="font-bold text-4xl text-center">
        {{ $t("pages.index.ebook") }}
      </div>
      <div
        class="container-inner mx-auto flex items-center justify-center flex-col text-center"
      >
        <img
          alt="content"
          class="object-cover object-center h-80 rounded-lg"
          src="@/assets/ebook_cover.png"
        />

        <h2 class="title-font text-xl font-medium text-gray-900 mb-3">
          {{ $t("pages.index.free_ebook") }}
        </h2>
        <p class="leading-relaxed text-base pb-4">
          {{ $t("pages.index.ebook_text") }}
        </p>
        <button
          v-if="!isDownloading"
          class="text-sm text-white tracking-wide font-semibold py-3 px-10 rounded-lg inline-flex shadow-lg items-center bg-green-500 hover:bg-green-600"
          @click="downloadWithAxios()"
        >
          {{ $t("pages.index.download") }}
        </button>
        <button
          v-if="isDownloading"
          class="text-sm text-white tracking-wide font-semibold py-3 pr-10 rounded-lg inline-flex shadow-lg items-center bg-green-500"
        >
          <img
            src="@/assets/spinner.svg"
            alt="spinner"
            class="h-5 w-5 ml-3 mr-2"
          />
          {{ $t("pages.index.download") }}
        </button>
      </div>
    </div>
    <!-- END eBook -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  metaInfo() {
    var locale = this.$i18n.locale;
    return {
      title: locale == "gr" ? "Βιβλία | MedInDiet" : "Books | MedInDiet",
      meta:
        locale == "gr"
          ? [
              {
                name: "description",
                content:
                  "Δρ. Αργυρίδου Σταυρούλα, Εγγεγραμμένη Κλινική Διαιτολόγος- Διατροφολόγος. Εξειδίκευση στην πρόληψη του Διαβήτη Τύπου ΙΙ & των Καρδιαγγειακών Νοσημάτων",
              },
              {
                name: "keywords",
                content:
                  "Διαιτολόγος, Διαιτολόγος Κύπρος, Διαιτολόγος Λευκωσία, Διατροφολόγος Κύπρος, Διατροφολόγος Λευκωσία, the wellness planner",
              },
            ]
          : [
              {
                name: "description",
                content:
                  "MedInDiet: Dr. Argyridou Stavroula, Registered Clinical Dietitian-Nutritionist Specialist in Type II Diabetes & Cardiovascular Disease Prevention, the wellness planner",
              },
              {
                name: "keywords",
                content: "Dietitian, Dietitian Cyprus, Dietitian Nicosia",
              },
            ],
      link: [
        { rel: "canonical", href: "https://medindiet.com/gr/about" },
        {
          rel: "alternate",
          hreflang: "el",
          href: "https://medindiet.com/gr/books",
        },
        {
          rel: "alternate",
          hreflang: "en",
          href: "https://medindiet.com/en/books",
        },
      ],
    };
  },
  data: function () {
    return {
      isDownloading: false,
    };
  },
  methods: {
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadWithAxios() {
      const url = require("~/../../src/assets/medindiet_ebook.pdf");
      const title = "Βιβλιάριο_Διατροφικής_Υποστήριξης.pdf";
      this.isDownloading = true;
      axios({
        method: "get",
        url,
        responseType: "blob",
      })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch()
        .finally(() => {
          this.isDownloading = false;
        });
    },
  },
};
</script>
