<template>
  <div>
    <div
      class="container mx-auto py-16 text-gray-800 flex flex-col text-center items-center justify-center"
    >
      <p class="text-4xl font-bold mb-6">
        {{ $t("pages.books.payment_success") }}
      </p>
      <img
        class="pt-16"
        src="@/assets/illustrations/payment_success.svg"
        alt="Payment Success"
      />
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    var locale = this.$i18n.locale;
    return {
      title:
        locale == "gr"
          ? "Επιτυχία Πληρωμής | MedInDiet"
          : "Payment Success | MedInDiet",
    };
  },
};
</script>
