<template>
  <div>
    <div class="overflow-x-hidden pt-16">
      <div class="container-inner mx-auto relative pb-16 lg:pb-0 text-center">
        <p>
          {{ $t("pages.appointments.preparation_1") }}
          <button @click="downloadWithAxios()" class="text-green-600 underline">
            {{ $t("pages.appointments.preparation_2") }}
          </button>
        </p>
      </div>
    </div>

    <vue-calendly
      url="https://calendly.com/drargyridou/appointment"
      :height="700"
    ></vue-calendly>
  </div>
</template>

<script>
import axios from "axios";

export default {
  metaInfo() {
    var locale = this.$i18n.locale;
    return {
      title:
        locale == "gr" ? "Ραντεβού | MedInDiet" : "Appointments | MedInDiet",
      link: [
        { rel: "canonical", href: "https://medindiet.com/gr/appointments" },
        {
          rel: "alternate",
          hreflang: "el",
          href: "https://medindiet.com/gr/appointments",
        },
        {
          rel: "alternate",
          hreflang: "en",
          href: "https://medindiet.com/en/appointments",
        },
      ],
    };
  },
  methods: {
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadWithAxios() {
      const urlEn = require("~/../../src/assets/medindiet_lipometrisi_eng.pdf");
      const urlGr = require("~/../../src/assets/medindiet_lipometrisi_gr.pdf");

      const title = "Οδηγίες Λιπομέτρησης (πληροφορίες για ασθενείς).pdf";
      axios({
        method: "get",
        url: this.$i18n.locale == "en" ? urlEn : urlGr,
        responseType: "blob",
      })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch();
    },
  },
};
</script>
