<template>
  <div>
    <div
      class="container mx-auto py-16 text-gray-800 flex flex-col text-center items-center justify-center"
    >
      <p class="text-4xl font-bold mb-6">
        {{ $t("pages.books.something_went_wrong") }}
      </p>
      <p class="text-xl mb-16">
        {{ $t("pages.books.payment_error") }}
      </p>
      <img src="@/assets/illustrations/payment_error.svg" alt="Payment Error" />
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    var locale = this.$i18n.locale;
    return {
      title:
        locale == "gr"
          ? "Αποτυχία Πληρωμής | MedInDiet"
          : "Payment Failure | MedInDiet",
    };
  },
};
</script>
